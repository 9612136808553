.cancelInterview-popup-heading{
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.025em;
  color: #E80512;
}
.popup-main-box{
  width:100%;
  height: 100vh;
  background-color: black;
  opacity: 0.5;
  top:0;
  position: absolute;
  z-index: 20;
}
.popup_box{
  width:450px;
  min-height:300px;
  background-color: white;
  padding:30px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 20;
  border-radius: 5px;
  .popup_heading{
    font-family: 'Poppins';
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.025em;
  color: #E80512;
  }
  .popup_box{
    display: flex;
  }
  .horizantal_line{
    color:#C9C9C9;;
  }
  select{
    height: 36px;
    width:350px;
    border: 1px solid #C9C9C9;
    box-sizing: border-box;
    border-radius: 4px;
    background-color: white;
    color:grey;
  }
  .buttons {
    margin-top:50px;
    margin-left: 10px;
  }
  .buttons button{
    width:160px;
    margin-right: 10px;
    border:1px solid #C9C9C9;
    height:36px;
    border-radius: 5px;
    font-weight: bold;
  }
  .buttons button:nth-child(1){
    background-color: white;
  }
}
.extra-desc{
  margin-top:20px;
}
.Background_none{
  background-color: none;
}
.Background_color{
  background-color: #E80512;
  color:white;
}
.cancel_interview_popup_heading{
  display: flex;
  align-items: center;
  justify-content: space-between;
  .popup_heading{
    margin-bottom:0;
  }
  img{
    cursor: pointer;
  }
}
.main_sidebar_cross_btn{
  height:25px;
  width:25px;
  box-sizing: border-box;
  position: absolute;
  z-index: 5;
  top:30px;
  right:530px;
  color:white;
  cursor: pointer;
}

#Reason{
  padding-left:5px;
  option{
    font-size:16px;
  }
}

.disabled{
  color:#555555;
  .rounds-button-group button{
    background-color: rgb(202, 200, 200);
    color:rgb(202, 200, 200);;
    color:white;
  }
  .disabled_button{
    margin-top:20px;
  .firstP{
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 20px;
    letter-spacing: 0.025em;
    color: #E80512;
    margin-bottom: 0px;
  }
  .secondP{
    span{
      font-weight: 600;
    }
    font-family: 'Poppins';
    font-size: 12px;
    letter-spacing: 0.025em;
    color: #3D3D3D;
  }
  }
  .rounds-button-group{
    button{
      border:1px solid rgb(202, 200, 200);
      background-color: #9A9A9A;
      cursor: not-allowed;
    }  
  }
}

.temporary-disabled{
  border:1px solid rgb(202, 200, 200);
  background-color: #9A9A9A;
  cursor: not-allowed;
}