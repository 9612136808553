.result-clarity-container {

    .indicators {
        border-radius: 51%;
        height: 0.9em;
        width: 0.9em;
        margin-right: 0.8em;
    }

    #greenIndicator {
        background-color: #0B8701;
    }

    #limeIndicator {
        background-color: #84C51B;
    }

    #yellowIndicator {
        background-color: #DE9811;
    }

    #redIndicator {
        background-color: #B60000;
    }

    #blackIndicator {
        background-color: #000000;
    }

    .rating-label {
        font-family: 'Poppins';
        font-weight: 600;
        font-size: 12px;
        letter-spacing: 0.005em;
        color: #3D3D3D;
        margin-bottom: 5px;
    }

    .rating-sub-label {
        font-family: 'Poppins';
        font-weight: 500;
        font-size: 12px;
        letter-spacing: 0.005em;
        color: #3D3D3D;
        margin-left: 35px;
    }

    .recommendation {
        font-weight: 400;
    }

    .arrow {
        display: flex;
        flex: 1;
        color: #C7C7C7;
    }

    .arrow-seperator {
        width: 100% !important;
        border-top: 2px solid;
        margin-bottom: 0.5rem;
        margin-top: 0.5rem;
        margin-left: 10px;
        margin-right: -10px;
    }

    .description-label {
        font-family: 'Poppins';
        font-weight: 400;
        font-size: 12px;
        letter-spacing: 0.005em;
        color: #6C6C6C;
    }





}