.heading {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #0d3270;
}

.form-row-style {
  display: flex;
  flex-wrap: wrap;
  margin: 12px 0px;
}

.feedback-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin: 25px 0px;
}

.form-row-style-10 {
  display: flex;
  flex-wrap: wrap;
  margin: 5px 0px;
}

.label {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 19px;
  margin-top: 5px;
  letter-spacing: 0.025em;
  color: #3d3d3d;
  width: 15%;
}

.asterisk {
  color: #d94c4c;

}

.info-button {
  color: #3d3d3d;
  width: 15px !important;
  height: 15px !important;
  align-self: flex-start;
  margin-right: 5px;
}

.error-color {
  color: #d94c4c;
}

.form-value {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 19px;
  letter-spacing: 0.025em;
  color: #b3b1b1;
  width: 80%;
}

.form-value-feedback {
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 12px;
  line-height: 19px;
  letter-spacing: 0.025em;
  color: #b3b1b1;
  width: 450px;
}

.form-value-feedback-full {
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 12px;
  line-height: 19px;
  letter-spacing: 0.025em;
  color: #b3b1b1;
  width: 80%;
}

.form-value-codetext {
  line-height: 19px;
  letter-spacing: 0.025em;
  background: #000;
  border-radius: 4px;
  width: 100%;
  font-style: italic;
}

.form-value-codetext-theory {
  line-height: 19px;
  letter-spacing: 0.025em;
  background: #EDEDED;
  border-radius: 4px;
  width: 100%;
  font-style: italic;
  margin: 20px 20px 0px 20px !important;
}

.width-value-field {
  width: 80%;
}

.add-more {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 19px;
  letter-spacing: 0.025em;
  color: #ffffff;
  background: #3d3d3d;
  width: 35px;
  height: 35px;
  align-self: center;
  margin-left: 10px;
}

.add-more:focus {
  opacity: 0.8;
}

.add-more-text-button {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 19px;
  letter-spacing: 0.025em;
  color: #ffffff;
  background: #3d3d3d;
  height: 38px;
  border: 0px;
  padding: 0px 10px;
}

.add-more-text-button:hover {
  opacity: 0.8;
}

.add-more-text-button:disabled {
  opacity: 0.36;
  cursor: not-allowed;
  color: #FEFEFE;
}

.delete-bg {
  font-family: 'Poppins';
  border: 1px solid #3d3d3d;
  background: #ffffff;
  width: 35px;
  height: 35px;
  align-self: center;
  margin-left: 10px;
}

.delete-bg:focus {
  opacity: 0.5;
}

.questions-bg {
  border: 1px solid #d1cece;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 30px;
}

.width-50 {
  width: 50%;
}

.width-30 {
  width: 30%;
}

.width-40 {
  width: 40%;
}

.width-10 {
  width: 10%;
}

.width-15 {
  width: 15%;
}

.width-20 {
  width: 20%;
}

.feedback-subheading {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #3d3d3d;
  margin-top: 100px;
}

.mt-40 {
  margin-top: 40px;
}

.radio-labels {
  font-family: "Poppins";
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  letter-spacing: 0.025em;
}

.border-recommendation {
  border: 1px solid #C4C4C4;
  padding: 5px 10px 0px 10px;
  margin-right: 8px;
  width: 200px;
  color: #3D3D3D;
}

.strongly-proceed-border-recommendation {
  border: 2px solid #0F6908;
  color: #0F6908;
}

.proceed-border-recommendation {
  border: 2px solid #71A41F;
  color: #71A41F;
}

.reject-border-recommendation {
  border: 2px solid #DA6C1D;
  color: #DA6C1D;
}

.strongly-reject-border-recommendation {
  border: 2px solid #E23939;
  color: #E23939;
}

.field-header {
  font-family: Poppins;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.025em;
  text-transform: uppercase;
  color: #0D3270;
  margin-bottom: 10px;
  margin-top: 20px;
}

.add-skills {
  font-family: Poppins;
  font-weight: 600;
  font-size: 13px;
  line-height: 19px;
  letter-spacing: 0.025em;
  color: #FFFFFF;
  background: #3d3d3d;
  height: 35px;
  align-self: center;
  border: 0px;
  padding: 0px 20px;
}

.add-skills:hover {
  opacity: 0.8;
}

.add-skill-modal-content {
  width: 80%;
}

.add-skill-modal-header {
  font-family: Poppins;
  font-weight: bold;
  font-size: 23px;
  letter-spacing: 0.055em;
  text-transform: uppercase;
  color: #011C67;
}

.add-skill-subtitle {
  font-family: Poppins;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.025em;
  color: #3D3D3D;
}

.add-skill-checkbox-header {
  font-family: Poppins;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.025em;
  color: #6D6C6C;
  text-transform: uppercase;
  padding: 15px 0px;
}

.add-skill-checkbox-label {
  font-family: Poppins;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.025em;
  color: #3D3D3D;
  margin: 0px;
  align-self: flex-start;
}

.code-added {
  font-family: Poppins;
  font-weight: 500;
  font-size: 12px;
  line-height: 19px;
  letter-spacing: 0.015em;
  color: #528828;
}

.optional-text {
  font-family: Poppins;
  font-size: 13px;
  letter-spacing: 0.02em;
  color: #8E1919;
}