.audit-report-main-wrapper {
  min-height: 61px;
  background: #1844ad;
  position: relative;

  .heading-nav {
    font-family: "Poppins";
    font-weight: 700;
    font-size: 18px;
    line-height: 0%;
    text-align: center;
    letter-spacing: 0.095em;
    text-transform: uppercase;
    color: #ffffff;
    left: 38.5%;
    position: absolute;
  }

  .download-Box{
    position: absolute;
     left: 80%;
     .download-arrow{
       margin-right: 6px;
       margin-bottom: 3px;
     }

     .download-box-typography{
          color: white;
     }
  }
}


.FeedbackBox-main-wrapper {
  height: 156px;
  width: 850px;
  background: #ffffff;
  // background-color: red;
  box-shadow: -4px -5px 54px -17px rgba(0, 0, 0, 0.13);
  border-radius: 14px;
  margin: 10px auto;

  .FeedbackBox-child-wrapper {
    // background-color: yellow;
    background: #f5f8ff;
    width: 826px;
    height: 130px;
    border-radius: 6px;

    .audit-feedback-h {
      width: 214px;
      height: 12px;

      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 124.4%;
      /* or 17px */

      letter-spacing: 0.005em;
      // position:relative;
      color: #123fbc;
    }

    .audit-feedback-h-child1 {
      margin-top: 30px;

      .a-f-h1 {
        width: 19px;
        height: 15px;

        margin-top: 10px;
        margin-right: 3px;

        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 30px;
        line-height: 0%;
        /* or 0px */

        letter-spacing: 0.025em;

        color: #303e61;
        // display: inline-block;
      }

      #a-f-h2 {
        width: 151px;
        height: 15px;

        font-family: "Poppins";
        font-style: normal;
        font-weight: 300;
        font-size: 15px;
        line-height: 0%;
        /* or 0px */
        // display: inline-block;
        letter-spacing: 0.025em;

        color: #52659b;
      }

      .a-f-h3 {
        width: 159px;
        height: 26px;

        background: #0b8701;
        border-radius: 6px;

        margin-top: 15px;

        #a-f-h4 {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 600;
          font-size: 15px;
          line-height: 124.4%;
          letter-spacing: 0.035em;
          color: #ffffff;
        }
      }
    }

    .feedback-rating-h {
      width: 214px;
      height: 12px;

      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 124.4%;
      letter-spacing: 0.005em;
      color: #3d3d3d;
    }

    .feedback-r-child1 {
      margin-top: 30px;

      #f-r-h1 {
        width: 19px;
        height: 15px;

        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 30px;
        line-height: 0%;
        letter-spacing: 0.025em;
        color: #3d3d3d;
      }

      #f-r-h2 {
        width: 151px;
        height: 15px;

        font-family: "Poppins";
        font-style: normal;
        font-weight: 300;
        font-size: 15px;
        line-height: 0%;
        /* or 0px */

        letter-spacing: 0.025em;

        color: #545455;
      }

      .f-r-h3 {
        width: 159px;
        height: 26px;
        background: #a3a3a3;
        border-radius: 6px;
        margin-top: 15px;
        margin-right: 25px;

        #f-r-h4 {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 600;
          font-size: 15px;
          line-height: 124.4%;
          /* or 19px */

          letter-spacing: 0.035em;

          color: #ffffff;
        }
      }
    }

    .post-audit {
      width: 83px;
      height: 12px;

      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      line-height: 124.4%;
      /* or 16px */
      margin-right: -110px;

      margin-top: -15px;
      letter-spacing: 0.005em;

      color: #3d3d3d;
    }

    #p-a-h1 {
      width: 244px;
      height: 12px;

      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 124.4%;
      /* or 15px */

      //   text-align: right;
      letter-spacing: 0.005em;
      margin-right: -50px;
      margin-top: 8px;

      color: #797979;
    }

    #p-a-h2 {
      width: 176px;
      height: 32px;

      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 27px;
      line-height: 124.4%;

      text-align: right;
      letter-spacing: 0.005em;
      margin-right: 10px;
      margin-top: 15px;

      color: #06c461;
    }

    #p-a-h-2-img {
      margin-top: 15px;
    }

    #p-a-h-2-img-overrated {
      margin-top: 22px;
    }
    #p-a-h-2-img-overrated img {
      height: 17px;
      margin-bottom: 4px;
    }

    #p-a-h-2-img-underrated {
      margin-top: 22px;
    }

    #p-a-h-2-img-underrated img {
      height: 17px;
      margin-bottom: 4px;
      // transform: rotate(180deg);
    }
  }
}

//Body..........

.Body {
  width: 850px;
  // height: 1917px;
  background: #ffffff;
  // background-color: pink;
  box-shadow: -4px -5px 54px -17px rgba(0, 0, 0, 0.13);
  border-radius: 14px;
  margin: 10px auto;
  //    position: relative;

  .assessment-tp {
    width: 310px;
    height: 12px;
    font-family: "Poppins";
    font-weight: 600;
    font-size: 17px;
    line-height: 124.4%;
    /* or 21px */

    letter-spacing: 0.005em;

    color: #123fbc;
    // position: relative;
  }

  .assessment-tp-list {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 124.4%;
    letter-spacing: 0.005em;
    color: #3d3d3d;
  }

  .summary-tp {
    width: 310px;
    height: 12px;
    font-family: "Poppins";
    font-weight: 600;
    font-size: 17px;
    line-height: 124.4%;
    /* or 21px */

    letter-spacing: 0.005em;

    color: #123fbc;
  }

  .Feedback_summary_h1 {
    width: 354px;
    height: 12px;
    margin-right: 30px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 124.4%;
    /* or 16px */

    letter-spacing: 0.005em;

    color: #3d3d3d;
  }

  .highlighttext{
    width: 793px;
    background: #F5F8FF;
  }

  .lowlights_downarrow{
    .uparrow{
      margin-left: -15px;
    }
  }
  .lowlights_downarrow img {
    width: 19px;
    height: 12px;

  }
  .comment-label{
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 12px;
    line-height: 124.4%;
    letter-spacing: 0.005em;
    color: #848484;
    margin: 15px 0 5px 0;
    text-decoration: underline;

  }

  .comment-data{
    font-family: 'Poppins';
    font-weight: 300;
    font-size: 12px;
    line-height: 124.4%;
    letter-spacing: 0.005em;
    color: #464646;
    max-width: 600px;
    white-space: pre-line;
  }
  .corections_h {
    // width: 232px;
    height: 12px;

    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 124.4%;
    /* or 15px */

    letter-spacing: 0.005em;

    color: #848484;
    margin-top: -10px;
    // background-color: red;
  }

  .corections_text {
    // width: 609px;
    min-height: 32px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 124.4%;
    /* or 15px */

    letter-spacing: 0.005em;

    color: #464646;
  }

  .skilltable{
    margin-left: 2px;
    .noborders{
      border-bottom: 0px;
    }
  }
  .noborder{
    border-bottom: 0px;
    box-shadow: none;
  }

  .skill-rating-tp {
    width: 310px;
    height: 12px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 124.4%;
    /* or 21px */

    letter-spacing: 0.005em;

    color: #123fbc;
  }

  .skill_rating_audit {
    color: #123fbc;

    height: 12px;
    font-weight: 600;
    font-size: 13px;
    line-height: 124.4%;

    letter-spacing: 0.005em;
  }

  .audit_rating_heading {
    // width: 159px;
    height: 12px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 124.4%;
    /* or 16px */

    letter-spacing: 0.005em;

    color: #6f84b4;
  }

  .initial_rating_heading {
    width: 159px;
    height: 12px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 124.4%;
    /* or 16px */

    letter-spacing: 0.005em;

    color: #848484;
  }

  .skill_rating_initial {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 124.4%;
    /* or 16px */

    letter-spacing: 0.005em;

    color: #3d3d3d;
  }

  .quality-rating-tp {
    width: 310px;
    height: 12px;

    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 124.4%;
    /* or 21px */

    letter-spacing: 0.005em;

    color: #123fbc;
  }

  .imgBox1 {
    width: 290px;
    height: 78px;

    background: #f5f8ff;
    border-radius: 58px;
    display: flex;
    align-items: center;
  }

  .imgBox1 img {
    width: 49px;
    height: 49px;
  }

  .ratingquality{
    display:"flex";
    padding-left: 5px;
  }

  .rating {
    margin-left: 10px;
    margin-top: -20px;
    margin-bottom: 10px;
    width: 60px;
    height: 12px;

    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 21px;
    line-height: 124.4%;
    /* or 26px */

    letter-spacing: 0.005em;

    color: #3d3d3d;
  }

  .rating-text {
    margin-left: 10px;
    // width: 87px;
    height: 12px;

    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 124.4%;
    /* or 20px */

    letter-spacing: 0.005em;

    color: #1844ad;
  }

  .misc-tp {
    width: 310px;
    height: 12px;

    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 124.4%;
    /* or 21px */

    letter-spacing: 0.005em;

    color: #123fbc;
  }

  .missing-info {
    // width: 298px;
    min-height: 12px;
    padding-left:20px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 124.4%;
    /* or 17px */

    letter-spacing: 0.005em;
    // background-color: red;
    color: #5c5c5c;
  }

  .unordered-list1 {
    // width: 718px;
    min-height: 32px;
    margin-top: 2px;
    margin-bottom: 30px;
    padding-left: 20px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 139.4%;
    /* or 17px */

    letter-spacing: 0.005em;

    color: #464646;
  }

  .appro_img{
    height:16px;
  }
  .over_img{
    height:13px;
  }
  .under_img{
    height:14px;
  }
}
