.question_card_preview {
    .font-style
    {
        font-family: 'Poppins';
        letter-spacing: 0.005em;
    }
    .question-link
    {
        font-size: 11px;
        font-weight: 600;
        color: #5B87FF;
        text-decoration: underline;
        cursor: pointer;
    }
    .mb-2p{
        margin-bottom: 2px;
    }
    .ml-10{
        margin-left: 10px;
    }
    .mr-5p{
        margin-right: 5px;
    }
    .font-11 {
        font-size: 11px;
    }

    .font-13 {
        font-size: 13px;
    }

    .bold-400 {
        font-weight: 400;
    }

    .bold-500{
        font-weight: 500;
    }

    .bold-600 {
        font-weight: 600;
    }

    .bold-900 {
        font-weight: 900;
    }

    .fg-gold {
        color: #C9B861;
    }

    .fg-red {
        color: #B60000;
    }

    .fg-gray4 {
        color: #535353;
    }

    .fg-black {
        color: #3D3D3D;
    }

    .nmt-5 {
        margin-top: -5px;
    }

    .fg-green {
        color: #47AC45;

    }

    .td-none:hover {
        text-decoration: none;
    }

    .white-space{
        white-space: break-spaces;
    }

    .tag{
        margin-top: -13px;
    }

    .width-15{
        min-width: 15px;
    }
}