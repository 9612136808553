a{
  all:unset;
  cursor: pointer;
}
button{
  cursor: pointer;
}

.DetailedScheduled{
    .m-b-10{
        margin-bottom: 10px;
    }
    .txt-align-right{
        text-align: right;
    }
    .txt-capitalize{
        text-transform: capitalize;
    }
    .text-candidate-not-joined{
        font-family: Poppins;
        font-weight: 600;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 0.025em;
        color: #3D3D3D;
    }
    
    .subtext-candidate-not-joined{
        font-family: Poppins;
        font-weight: 300;
        font-size: 11px;
        line-height: 13px;
        letter-spacing: 0.025em;
        color: #3D3D3D;
    }

    .text-client-notified{
        font-family: Poppins;
        font-weight: 600;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 0.025em;
        color: #D77E15;
    }
}

.btnConfirmSwal{
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 117.4%;
    text-align: center;
    letter-spacing: 0.025em;
    color: #123FBC !important;
    background: #FFFFFF !important;
    border: 1px solid #123FBC !important;
    box-sizing: border-box;
    border-radius: 2px;
    padding: 5px 16px;
}
.btnCancelSwal{
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 117.4%;
    text-align: center;
    letter-spacing: 0.025em;
    color: #FFFFFF !important;
    background: #EC2121 !important;
    border-radius: 2px;
    padding: 5px 16px;
}

.details{
  background-color: #E5EFFC;
  padding:30px;
  font-family: Poppins;
  .heading-client{
    font-size:15px;
    font-weight: 500;
  }
  .position-client-details{
    font-size: 13px;
    margin-bottom: 10px;
    .positon-client-details-box{
      margin-top:5px;
      font-weight: 500;
    }
  }
  .rounds-button-group button{
    width: 178px;
    height: 36px;
    border: 1px solid #123FBC;
    box-sizing: border-box;
    border-radius: 4px;
    margin-right: 10px;
  }

  .resume-optional-warning{
    font-size: 12px;
  }
  .second-group{
    background-color: #123FBC;
    color:white;
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.025rem;
    img{
      margin-right: 10px;
    }
  }
  .first-button{
    background: none;
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 0.025rem;
    .Items-logo-link{
      margin-right: 10px;
    }
  }
  .interview-details{
    padding-left:15px;
    font-size:13px;
    .Interview_details_logo{
      margin-right: 10px;
    }
  }
  .candidate-did-join{
    margin-top:20px;
    p:nth-child(1){
      font-weight: 600;
      font-size: 14px;
      span{
        color:#F38300;
        text-decoration: underline;
        cursor: pointer;
      }
    }
    p:nth-child(2){
      font-size: 12px;
    }
    .notify-text{
      margin-bottom: 0px;
    }
  }
  .cancel-interview{
    margin-top:20px;
    width:95%;
    height: 36px;
    border: 1px solid #E80512;
    box-sizing: border-box;
    border-radius: 4px;
    color: #E80512;
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 14px;
    line-height: 117.4%;
  }
}
.feedback-sidebar{
  margin-top:20px;
  padding:30px;
  min-height: 100px;
  background: #FFFFFF;
  border: 1px solid rgba(165, 178, 215, 0.49);
  box-sizing: border-box;
  .feedback-heading{
    font-weight: 500;
  }
  .feedback-button{
    cursor: pointer;
    height: 36px;
    background: #E5EFFC;
    border: 1px solid #123FBC;
    border-radius: 4px;
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 14px;
    text-align: center;
    letter-spacing: 0.025em;
    color: #123FBC;
    width: 189px;
    height:36px;
    line-height: 33px;
    margin-top:10px;
  }
}
.comments-sidebar{
  background: #FFFFFF;
  border: 1px solid rgba(165, 178, 215, 0.49);
  box-sizing: border-box;
  min-height: 100px;
  padding:32px;
  margin-top: 20px;

  .comment-row{
    display: flex;
    justify-content: space-between;
  }

}
.round-history-sidebar{
  min-height: 80px;
  background: #FFFFFF;
  border: 1px solid rgba(165, 178, 215, 0.49);
  box-sizing: border-box;
  padding:33px;
  margin-top:20px;
  // overflow-y: scroll;
}
.roundHistoryDesign{
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  .roundHistoryText{
    margin:0;
  }
  p:nth-child(1){
    font-weight: bold;
  }
  .first_col{
    p:nth-child(2){
      font-size:12px;
      color:grey;
    }
  }
}