.guide_preview_main_container {
    line-break: anywhere;

body {
    background-color: #eceef3;
    padding: 0px;
    margin: 0px;
}

    a {
        text-decoration: none;
    }
.guide_preview_container {
    max-width: 850px;
    height: fit-content;
    background: #FFFFFF;
    box-shadow: -4px -5px 54px -17px rgba(0, 0, 0, 0.13);
    border-radius: 14px;
    margin: 0px auto;
    font-family: 'Poppins';
    font-style: normal;
    letter-spacing: 0.005em;

    .jd-link {
        display: flex;
        grid-gap: 8px;
        gap: 8px;
        padding: 12px 20px;
        background: #E3EBFF;
        height: 20px;
        width: 140px;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        margin-top: 22px;
        font-weight: 600;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 0.015em;
        color: #123FBC;
        cursor: pointer;
    }
}

.guide_preview_container>div {
    padding-left: 38px;
    padding-right: 10px;
}

.guide_preview_role {
    font-weight: 600;
    font-size: 20px;
    color: #3D3D3D;
    padding-top: 20px;
}

.guide_preview_round_name {
    font-weight: 500;
    font-size: 14px;
    color: #A3A3A3;
    margin-top: -10px;

    a {
        font-weight: 500;
        font-size: 14px;
        color: #3D3D3D;
    }
}

hr {
    border: 1px solid #CCD0DB;
}

.guide_preview_details {
    padding-bottom: 20px;

    h2 {

        font-weight: 600;
        font-size: 17px;
        color: #123FBC;
    }

    .guide_preview_heading {
        font-weight: 600;
        font-size: 14px;
        color: #6B6B6B;
    }

    ul {
        font-weight: 300;
        font-size: 12px;
        color: #3D3D3D;

        a {
            font-size: 12px;
            color: #3D3D3D;
        }
    }
    .main-box
    {
        width: 98%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        background: #FFFFFF;
        padding: 10px;
        border-radius: 8px;
        cursor: pointer;
        .comment
        {
            margin-left: 20px;
            width: 14px;
            height: fit-content;
            display: flex;
            align-items: center;
        }
       
    }
    .blue-box
    { 
        background: #F3F6FF;
    }
    .light-yellow-box
    {
        background: #FFF6EF;
    }
    .dark-yellow-box
    {
        background: #FFE2CF;
    }
}

#topic{
    color:rgba(18, 63, 188, 1);
    font-size: 12px;
    font-weight: 600;
    line-height: 15px;
    letter-spacing: 0.005em;
    text-align: left;
}

#topicLimit{
    color: rgba(132, 132, 132, 1);
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0.005em;
    text-align: left;
    span{
        font-weight: 600;
    }
}

#easy_difficulty{
    color:rgba(71, 172, 69, 1) !important;
}
#medium_difficulty{
    color:rgba(201, 184, 97, 1);
}
#hard_difficulty{
    color:red !important;
}
.difficulty{
    padding-top: 0.25em;
    padding-left: 2.1em;
    padding-right: 0.25em;
    font-size: 11px !important;
    font-weight: 600 !important;
    line-height: 14px;
    letter-spacing: 0.005em;
    text-align: left;
}

.guide_preview_chip {
    display: inline-block;
    height: 20px;
    width: fit-content;
    border-radius: 6px;
    font-weight: 600;
    font-size: 11px;
    line-height: 19px;
    letter-spacing: 0.025em;
    color: #FFFFFF;
    padding: 0px 7px 0px 7px;
    user-select: none;
    margin: 0px 5px 2px 5px;
}

.guide_preview_circle {
    height: 12px;
    width: 12px;
    border-radius: 50%;
    display: inline-block;
    margin-bottom: -2px;
}

.guide_preview_result {
    font-weight: 600;
    font-size: 12px;
    color: #3D3D3D;
    margin-left: 10px;
}

.topicList-Container{
    display: flex;
    flex-direction: row;
    margin-top: -10px;
}

.questionTopicList {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 124.4%;
    letter-spacing: 0.005em;
    color: #123FBC;
  }

  .questionAskText{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 124.4%;
    letter-spacing: 0.005em;
    color: #3D3D3D;
    margin-top: 2px;
  }

.guide_preview_content_box {
    // margin-left: -27px;
    list-style-type: none;

    li {
        margin: 5px 0px 5px 0px;
    }

    .no-eval-param{
        font-weight: 300;
        font-size: 12px;
        color: #3D3D3D;
        margin-left: 20px;
    }
}

.guide_preview_box_1 {
    white-space: break-spaces;
    // margin-left: -10px;
    .imp-points{
      color: #CC0F0F;
      font-weight: 600;
    }
}

.guide_preview_box_2 {
    // margin-left: -32px;
}

.guide_preview_box_3 {
    // margin-left: -14px;
}

.guide_preview_box_4 {
    // margin-left: -37px;
}

.guide_preview_soft_skill {
    // margin-left: -15px;
}

.guide-questions-ask-label{
    font-weight: 300;
    font-size: 12px;
    color: #3D3D3D;
}

.guide_preview_result_symbol {
    font-weight: 400;
    font-size: 12px;
    color: #3D3D3D;
    width:55px;
}

.guide_preview_result_details {
    font-weight: 400;
    font-size: 12px;
    color: #6C6C6C;
    padding: 0 5px;
}    

.guide_preview_arrow {
    height: 0px;
    border: 1px solid #C7C7C7;
    border-radius: 2px;
    display: inline-block;
    margin-bottom: 3px;
}

.guide_preview_arrow_1 {
    width: 71px;
}

.guide_preview_arrow_2 {
    width: 93px;
}

.guide_preview_arrow_3 {
    width: 106px;
}

.guide_preview_arrow_4 {
    width: 130px;
}

.guide_preview_arrow_5 {
    width: 60px;
}

.guide_preview_arrow_6 {
    width: 38px;
}

.guide_preview_arrow_7 {
    width: 65px;
}

.width-25{
    min-width: 25px;
}

.topicContainer{
    margin-top: 1em;
    .questionTopic{
        margin-bottom: -0.25em;
        font-size: 14px;
        font-weight: 700;
        line-height: 15px;
        letter-spacing: 0.005em;
        text-align: left;
        color:rgba(18, 63, 188, 1);
        margin-bottom: 3px;
    }
    .ques-sub-heading{
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 124.4%;
        letter-spacing: 0.005em;
        color: #848484;
        margin-bottom: 3px;
        }

    .flexBox {
        display: flex;
        margin-top: 5px;
        margin-bottom: 5px;

         .noteBox{
            width: 37px;
            height: 14px;
            left: 471px;
            top: 2012px;
            background-color: #DEE5F5;
            opacity: 0.8;
            border-radius: 3px;
         }

         .noteText{              
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 600;
                font-size: 11px;
                line-height: 16px;
                letter-spacing: 0.025em;
                color: #3D3D3D;
                margin-bottom: 5px;
                margin-left: 5px;
                margin-bottom: 5px;
                margin-left: 5px;
         }

    }
}

.guide_preview_arrow_head_right {
    border: 1px solid #C7C7C7;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    margin-left: -7px;
}

.guide_preview_view_button {
    text-decoration: underline;
    cursor: pointer;
}

.mb-5p{
    margin-bottom: 5px;
}
.mb-10p{
    margin-bottom: 10px;
}
.mb-20p{
    margin-bottom: 20px;
}
.bold-100 {
    font-weight: 100;
}

.bold-200 {
    font-weight: 200;
}

.bold-300 {
    font-weight: 300;
}

.bold-400 {
    font-weight: 400;
}

.bold-500 {
    font-weight: 500;
}

.bold-600 {
    font-weight: 600;
}

.bold-700 {
    font-weight: 700;
}

.bold-800 {
    font-weight: 800;
}

.bold-900 {
    font-weight: 900;
}

// ---------------Color pallet---------------
.bg-red {
    background: #B60000;

}

.bg-blue {
    background: #003EE9;
}

.bg-lightblue {
    background: #DEE5F5;
}
.bg-lightblue2 {
    background-color: #F3F5FA;
}

.bg-gray {
    background: #F2F2F2;
}

.bg-green {
    background: #0B8701;
}

.bg-green2 {
    background: #47AC45;
}

.bg-lightgreen {
    background: #84C51B;
}

.bg-gold {
    background: #DE9811;
}

.bg-gold2 {
    background-color: #C9B861;
}

.fg-red {
    color: #B60000;
}

.fg-blue {
    color: #003EE9;
}

.fg-blue2 {
    color: #123FBC;
}

.fg-lightblue {
    color: #DEE5F5;
}


.fg-lightblue2 {
    color: #6D80B7;
}

.fg-gray {
    color: #F2F2F2;
}

.fg-gray2 {
    color: #9E9E9E;

}

.fg-gray3 {
    color: #848484;
}

.fg-gray4 {
    color: #535353;
}

.fg-gray5 {
    color: #ADADAD;
}

.fg-gray6 {
    color: #cccccc;
}

.fg-green {
    color: #47AC45;

}

.fg-green2 {
    color: #348416;
}

.fg-gold {
    color: #C9B861;
}

.fg-black {
    color: #3D3D3D;
}

.fg-black2 {
    color: #595959;
}

.white-space{
    white-space: break-spaces;
}

.fg-lightblack {
    color: #777777;

}

.font-11 {
    font-size: 11px;
}

.font-12 {
    font-size: 12px;
}

.font-13 {
    font-size: 13px;
}

.font-18 {
    font-size: 18px;
}

.flexBox{
    display:flex;
}

.nmt-5 {
    margin-top: -5px;
}

.nmt-10 {
    margin-top: -10px;
}

.nmt-20 {
    margin-top: -20px;
}

.pml-5 {
    margin-left: 5px;
}
.pmr-5 {
    margin-right: 5px;
}
.pmlr-5 {
    margin: 0px 5px;
}
.pml-10 {
    margin-left: 10px;
}

.pml-20 {
    margin-left: 20px;
}

.pml-25 {
    margin-left: 25px;
}

.td-none:hover {
    text-decoration: none;
}

}

.guide_preview_box{
    display: flex; 
    flex-direction: column;
    gap: 30px;
    width: 100%;

    .guide_preview_topBar{
        background: #123FBC;
        border-radius: 2px;
        padding: 20px 60px;
        height: 61px;
        box-shadow: -1px -1px 30px -3px rgba(0, 0, 0, 0.06);
        margin: -20px -20px 0 -18px;
        padding-left: 100px; 
        font-family: 'Poppins';
        font-style: normal;
        color: #FFFFFF;
        .iv-logo{
            border-radius: 4px;
            height: 30px;
            margin-top: -6px;
        }
        
        .navbar-text{
            font-weight: 700;
            font-size: 20px;
            line-height: 0%;
            text-align: center;
            letter-spacing: 0.095em;
            text-transform: uppercase;
        }
        .share-link{
            width: 130px;
            height: 35px;
            background: rgba(0, 130, 250, 0.25);
            border-radius: 8px;
            margin-top:-8px;
            font-weight: 400;
            font-size: 14px;
            padding: 6px 10px;
            display:flex;
            gap:10px;
            cursor: pointer;
        }
    }    
}