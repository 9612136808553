.common-component-container {

    .w-50 {
        width: 50%;
    }

    .alignItemsEnd {
        // align-items: end;
    }

    .question {
        font-family: "Poppins";
        font-weight: 600;
        font-size: 13px;
        line-height: 17px;
        letter-spacing: 0.025em;
        color: #3D3D3D;
    }

    .validation-error {
        color: #B22B2B;
    }

    .asterisk {
        color: #B22B2B;
    }

    .response {

        .hint {
            // background: #EDF2FF;
            // border: 1px solid #C2D4F0;
            // border-radius: 8px;
            // padding: 20px;
            font-size: 13px;
        }

        .add-more {
            background: #3D3D3D;
            color: #fff;
            border-radius: 0px;
            border: 0px solid #3D3D3D;
            min-width: 30px;
        }

        .delete-button {
            color: #3D3D3D;
            background: #fff;
            border: 1px solid #3D3D3D;
            border-radius: 0px;
            min-width: 30px;
        }

        .w-100 {
            width: 100%;
        }

        .MuiMenu-list {
            display: flex !important;
            flex-direction: column !important;
        }

        .dropdown-text {
            font-family: 'Poppins';
            font-weight: 700;
            font-size: 14px;
            line-height: 124.4%;
            letter-spacing: 0.005em;
        }

        .dropdown-subtext {
            font-family: 'Poppins';
            font-weight: 500;
            font-size: 12px;
            line-height: 124.4%;
            letter-spacing: 0.005em;
            color: #757575;
        }

        .dropdown-group {
            width: 100%;
        }

        .select-wrapper{
            .dropdown{
               white-space: normal !important;

            }
        }
    }
}

.rating-static-block {
    .heading {
        font-family: 'Poppins';
        font-weight: 700;
        font-size: 16px;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #3D3D3D;
    }

    .sub-heading {
        font-family: 'Poppins';
        font-weight: 400;
        font-size: 13px;
        line-height: 20px;
        letter-spacing: 0.025em;
        color: #3D3D3D;
    }

    .ratings-bg {
        background: #FAFBFF;
        border-radius: 11px;
        padding: 15px;
    }
}